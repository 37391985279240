.irs--flat .irs-line {
    top: 25px;
    height: 3px;
    background-color: #d9dfe8;
    border-radius: 50px;
}

.irs--flat .irs-min, .irs--flat .irs-max {
    display: none;
}
.rg-slider .irs-bar {
    top: 25px;
    height: 3px;
    background-color:#00b074;
}
.irs--flat .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: -1px;
    left: 50%;
    width: 12px;
    height: 12px;
    margin-left: -3px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #00b074;
}
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
    background-color: #00b074;
}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
    border-top-color: #00b074;
}
.irs--flat .irs-handle.state_hover>i:first-child, .irs--flat .irs-handle:hover>i:first-child {
    background-color: #00b074;
}